import * as i from 'types';
import React from 'react';

import { Text } from 'common/typography';

import { Container } from './styled';

export const Instructor: React.FC<InstructorProps> = ({ position }) => {
  return (
    <Container position={position}>
      <Text $weight={700} $lineHeight="42">I</Text>
    </Container>
  );
};

type InstructorProps = {
  position: i.SpotLayoutPosition;
};

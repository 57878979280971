import * as React from 'react';
import qs from 'qs';
import { WindowLocation } from '@reach/router';
import { useTranslation, Trans, useI18next } from 'gatsby-plugin-react-i18next';

import { useAuthenticationUser, useSelector } from 'services/hooks';
import { formatWrittenDateYear } from 'services/formatDate';
import { Heading, Text } from 'common/typography';
import { Button } from 'common/interaction';

import {
  SeriesList, SeriesCard, SeriesContentContainer, SeriesAmountContainer,
} from './styled';

export const BookingMultipleSeries: React.FC<BookingMultipleSeriesProps> = ({
  setSelectedSerie, selectedSerie, submitBooking, location,
}) => {
  const queryParams = qs.parse(location?.search.replace('?', '') || '');
  const isWaitList = queryParams.waitList === 'true';
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const { determined, authenticated } = useAuthenticationUser();
  const series = useSelector((state) => state.classesDetail.series);

  React.useEffect(() => {
    if (determined && !authenticated) {
      navigate('/booking');
    }
  }, []);

  return (
    <>
      <Heading variant="black" as="h2">
        <Trans
          defaults="Select the package <break /> you want to spend."
          components={{ break: <br /> }}
        />
      </Heading>
      <Text $uppercase $weight={700} $margin="0 0 16px">{t('your packages')}</Text>
      <SeriesList>
        {series && series.map((serie) => (
          <SeriesCard
            isSelected={selectedSerie === serie.id}
            key={serie.id}
            onClick={() => setSelectedSerie(serie.id)}
          >
            <SeriesContentContainer>
              <Text $size={16} $uppercase $weight={700} $lineHeight="24">
                {serie.seriesName}
              </Text>
              <Text variant="gray" $lineHeight="24" $size={14}>
                {serie.unlimited
                  ? 'Unlimited workouts'
                  : `Valid until ${formatWrittenDateYear(serie.expirationDate)}`
                }
              </Text>
            </SeriesContentContainer>
            {!serie.unlimited && (
              <SeriesAmountContainer>
                <Text variant="primary" $size={20} $weight={700}  $lineHeight="24">
                  {serie.classesRemaining}/{serie.classCount}
                </Text>
              </SeriesAmountContainer>
            )}
          </SeriesCard>
        ))}
      </SeriesList>
      <Button variant="CTA" size="fullWidth" onClick={() => submitBooking(isWaitList)}>
        {t('confirm')}
      </Button>
    </>
  );
};

type BookingMultipleSeriesProps = {
  path: string;
  setSelectedSerie: (id: string) => void;
  selectedSerie?: string;
  submitBooking: (isWaitList?: boolean) => void;
  location?: WindowLocation;
}

import * as i from 'types';
import styled from 'styled-components';

export const Container = styled.div<ContainerProps>`
  position: absolute;
  top: ${({ position }) => position.y}px;
  left: ${({ position }) => position.x}px;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.gray.medium};
  border-radius: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

type ContainerProps = {
  position: i.SpotLayoutPosition;
};

import * as i from 'types';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { useAuthenticationUser, useDispatch, useSelector } from 'services/hooks';
import { isSameOrAfterToday } from 'services';
import { cancelBooking } from 'ducks/booking';
import { getUserClasses } from 'ducks/me';
import { Button } from 'common/interaction';
import { Heading, Text } from 'common/typography';
import { ConfirmModal } from 'common/layout';
import { selectActiveBooking } from 'selectors/booking';

import { ButtonContainer } from './styled';

export const BookingCTA: React.FC<BookingCTAProps> = ({ classDetail, submitBooking, isLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const classSeries = useSelector((state) => state.classesDetail.series);
  const booking = useSelector((state) => selectActiveBooking(state, classDetail.id));
  const [isModalOpen, openModal] = React.useState(false);
  const { authenticated } = useAuthenticationUser();

  const nextPage = () => {
    if (classDetail.hasLayout) {
      navigate(`/class/${classDetail.id}/spots`);
    } else if ((classSeries?.length || 0) > 1) {
      navigate(`/class/${classDetail.id}/series`);
    } else {
      submitBooking();
    }
  };

  const handleCloseModal = () => {
    openModal(false);
  };

  const handleOpenModal = () => {
    openModal(true);
  };

  const confirmCancel = () => {
    if (classDetail?.id) {
      dispatch(cancelBooking({
        classId: classDetail?.id,
        attendanceId: booking?.attendanceId,
        lateCancel: !booking?.cancellable,
      })).then(() => {
        handleCloseModal();
        dispatch(getUserClasses());
      });
    }
  };

  const waitlist = () => {
    if ((classSeries?.length || 0) > 1) {
      navigate(`/class/${classDetail.id}/series?waitList=true`);
    } else {
      submitBooking(true);
    }
    handleCloseModal();
  };

  if (!authenticated) {
    const redirectUrl = `/class/${classDetail.id}`;

    return (
      <>
        <Heading as="h2" variant="black">{t('you must be logged in to book this class')}</Heading>
        <Text $margin="0 0 32px">{t('you must be logged in to book a class')}</Text>
        <ButtonContainer>
          <Button
            variant="CTA"
            size="fullWidth"
            onClick={() => navigate('/register', { state: { redirectUrl } })}
          >
            {t('create account')}
          </Button>
          <Button
            size="fullWidth"
            onClick={() => navigate('/login', { state: { redirectUrl } })}
          >
            {t('login')}
          </Button>
        </ButtonContainer>
      </>
    );
  }

  if (booking && !classDetail?.isWaitlisted) {
    return (
      <>
        <Heading as="h2" variant="black">{t('you have booked this workout')}</Heading>
        <Text $margin="0 0 32px">{t('you are ready to workout')}</Text>
        {isSameOrAfterToday(booking.classDate) && (
          <ButtonContainer>
            <Button variant="warning" size="fullWidth" onClick={handleOpenModal}>
              {t('cancel booking')}
            </Button>
          </ButtonContainer>
        )}
        <ConfirmModal
          title={t('are you sure you want to cancel this class')}
          description={booking.cancellable
            ? `${t('you are about to cancel your class, are you sure')}`
            : `${t('you are about to cancel your class. a late fee shall be charged on your account')}`}
          isOpen={isModalOpen}
          closeModal={handleCloseModal}
          confirmAction={confirmCancel}
        />
      </>
    );
  }

  if (booking && classDetail?.isWaitlisted) {
    return (
      <>
        <Heading as="h2" variant="black">{t('you are on the waitlist')}</Heading>
        <Text $margin="0 0 32px">
          {t('you are now on the waiting list. as soon as a spot becomes available, we will register you for the class')}
        </Text>
        <ButtonContainer>
          <Button variant="warning" size="fullWidth" onClick={handleOpenModal}>
            {t('remove from waitlist')}
          </Button>
        </ButtonContainer>
        <ConfirmModal
          title={t('are you sure')}
          description={t('you will be removed from the waitlist')}
          isOpen={isModalOpen}
          closeModal={handleCloseModal}
          confirmAction={confirmCancel}
        />
      </>
    );
  }

  if (classSeries?.length === 0) {
    return (
      <>
        <Heading as="h2" variant="black">{t('you have no class credits')}</Heading>
        <Text $margin="0 0 32px">{t('buy class credits so you can book a workout')}</Text>
        <ButtonContainer>
          <Button variant="CTA" size="fullWidth" onClick={() => navigate('/pricing')}>
            {t('buy class credits')}
          </Button>
        </ButtonContainer>
      </>
    );
  }

  if (classDetail?.full && !classDetail?.waitListFull) {
    return (
      <>
        <Heading as="h2" variant="black">{t('shall we put you on the waitlist')}</Heading>
        <Text $margin="0 0 32px">
          {t('confirm that you want to be added to the waitlist')}
        </Text>
        <ButtonContainer>
          <Button variant="CTA" size="fullWidth" onClick={handleOpenModal}>
            {t('put me on the waitlist')}
          </Button>
        </ButtonContainer>
        <ConfirmModal
          title={t('you will be put on the waitlist')}
          description={t('as soon as a spot becomes available, we will register you for the class')}
          isOpen={isModalOpen}
          closeModal={handleCloseModal}
          confirmAction={waitlist}
        />
      </>
    );
  }

  return (
    <>
      <Heading as="h2" variant="black">{t('you are ready to book this class')}</Heading>
      <Text $margin="0 0 32px">{t('book this class by picking a spot')} </Text>
      <ButtonContainer>
        <Button variant="CTA" size="fullWidth" onClick={nextPage} isLoading={isLoading}>
          {classDetail?.hasLayout ? t('pick a spot') : t('book class')}
        </Button>
      </ButtonContainer>
    </>
  );
};

type BookingCTAProps = {
  id?: string;
  path: string;
  submitBooking: (isWaitList?: boolean) => void;
  classDetail: i.Class;
  isLoading?: boolean;
};

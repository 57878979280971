import * as React from 'react';
import { useTranslation, Trans, useI18next } from 'gatsby-plugin-react-i18next';

import { getBookings } from 'ducks/booking';
import { useDispatch } from 'services/hooks';
import { Button } from 'common/interaction';
import { Text } from 'common/typography';

import { ButtonContainer, BookingSuccessContainer } from './styled';

export const BookingSuccess: React.FC<BookingSuccessProps> = ({ id }) => {
  const { navigate } = useI18next();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(getBookings());
  }, []);

  return (
    <BookingSuccessContainer>
      <Text $size={30} $lineHeight="40" variant="black">{t('booking succesful')}</Text>
      <Text $margin="0 0 32px" $lineHeight="20">
        <Trans
          i18nKey="bookingdetail.you just booked your workout"
          defaults="You just booked your workout, awesome! While you <break /> mentally prepare yourself for a tough workout, wanna book <break /> another one?"
          components={{ break: <br /> }}
        />
      </Text>
      <ButtonContainer>
        <Button variant="CTA" size="fullWidth" onClick={() => navigate('/booking')}>
          {t('book another class')}
        </Button>
      </ButtonContainer>
    </BookingSuccessContainer>
  );
};

type BookingSuccessProps  = {
  id?: string;
  path: string;
};

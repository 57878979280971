import * as i from 'types';
import styled, { css } from 'styled-components';

export const Container = styled.div<ExtendedTouchableOpacityProps>`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: ${({ $position }) => $position.y}px;
  left: ${({ $position }) => $position.x}px;
  transition: background-color 0.3s, border 0.3s;

  ${({ $status }) => {
    switch ($status) {
      case 'Available': return css`
        border: 1px solid ${({ theme }) => theme.colors.primary};
        background-color: ${({ theme }) => theme.colors.white};
      `;
      case 'Enrolled':
      case 'Unavailable': return css`
        opacity: .5;
        background-color: ${({ theme }) => theme.colors.white};
        border: 1px solid ${({ theme }) => theme.colors.gray};
      `;
    }
  }}

  ${({ $selected }) => $selected && css`
    background-color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  `}

  ${({ $variant }) => $variant === 'circle' && css`
    border-radius: 20px;
  `}

  ${({ $variant }) => $variant === 'rectangle-horizontal' && css`
    width: 120px;
    height: 40px;
  `}

  ${({ $variant }) => $variant === 'rectangle-vertical' && css`
    width: 40px;
    height: 120px;
  `}
`;

type ExtendedTouchableOpacityProps = {
  $disabled?: boolean;
  $selected?: boolean;
  $status: i.SpotStatus;
  $position: {
    x: number;
    y: number;
  };
  $variant?: i.StudioLayoutSpotVariants;
};

export const Title = styled.div<TitleProps>`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray};
  text-transform: uppercase;
  font-weight: bold;

  ${({ $status }) => {
    switch ($status) {
      case 'Available': return css`
        color: ${({ theme }) => theme.colors.primary};
      `;
    }
  }}

  ${({ $selected }) => $selected && css`
    color: ${({ theme }) => theme.colors.white};
  `}
`;

type TitleProps = {
  $selected?: boolean;
  $status: i.SpotStatus;
};

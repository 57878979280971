import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import { getBookings } from 'ducks/booking';
import { useDispatch } from 'services/hooks';
import { Button } from 'common/interaction';
import { Text } from 'common/typography';

import { ButtonContainer } from './styled';

export const BookingSuccessWaitList: React.FC<BookingSuccessProps> = () => {
  const { navigate } = useI18next();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBookings());
  }, []);

  return (
    <>
      <Text $size={30} $lineHeight="40" variant="black">{t('you are on the waitlist')}</Text>
      <Text $margin="0 0 32px" $lineHeight="20">
        {t('you are now on the waiting list. as soon as a spot becomes available, we will register you for the class')}
      </Text>
      <ButtonContainer>
        <Button variant="CTA" onClick={() => navigate('/booking')}>
          {t('book another class')}
        </Button>
      </ButtonContainer>
    </>
  );
};

type BookingSuccessProps = {
  path: string;
};

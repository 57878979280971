import * as i from 'types';
import * as React from 'react';
import dayjs from 'dayjs';

import { useSelector } from 'services/hooks';
import { formatDay, formatWrittenDate, formatTime, generateBookingLabel } from 'services';
import { selectActiveBooking } from 'selectors/booking';
import { Text } from 'common/typography';
import { BookingLabel, BookingMeta } from 'modules/booking';

import { CardContainer, CardContent, CardHeader, CardImage, LabelContainer } from './styled';

export const BookingDetailCard: React.FC<BookingDetailCardProps> = ({
  item,
}) => {
  // Image url can be added between [] in the description, save it in a const and remove from description
  const description = item?.classDescription || '';
  const imageUrl = description.substring(
    description.indexOf('[') + 1,
    description.lastIndexOf(']'),
  );
  const cleanDescription = description.replace(`[${imageUrl}]`, '').split('<br/>').join('\n');
  const label = generateBookingLabel(item);
  const booking = useSelector((state) => selectActiveBooking(state, item.id));

  return (
    <CardContainer>
      {label && (
        <LabelContainer>
          <BookingLabel variant={label} />
        </LabelContainer>
      )}
      <CardImage imageUrl={imageUrl}>
        <Text $size={30} $lineHeight="40" variant="white">{item?.classType}</Text>
        <Text $size={16} variant="white">
          {formatTime(item?.classDate)} - {formatTime(dayjs(item?.classDate).add(item?.duration || 0, 'minutes').toDate())}
          {booking?.spotLabel && booking?.spotLabel !== '0' && ` • Spot: ${booking?.spotLabel}`}
        </Text>
      </CardImage>
      <CardContent>
        <CardHeader>
          <Text $size={18}>
            {formatDay(item?.classDate)} - {formatWrittenDate(item?.classDate)}
          </Text>
        </CardHeader>
        <BookingMeta
          site={item?.site}
          instructorName={item?.instructorName}
          classLength={item?.classLength}
        />
        <Text
          $margin="20px 0 0"
          $lineHeight="20"
        >
          {cleanDescription}
        </Text>
      </CardContent>
    </CardContainer>
  );
};

type BookingDetailCardProps = {
  item: i.Class;
};
